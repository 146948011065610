import React from 'react'
import { graphql } from 'gatsby'

const BlogPostTemplate = ({ data }) => {

    const post = data.markdownRemark
    var sPostHeaderClass = "masthead " + post.frontmatter.imageClassName;

    return (
      <div>
        {/* Page Header */}
        <header className={sPostHeaderClass}>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-10 mx-auto">
                <div className="post-heading">
                  <h1>{post.frontmatter.title}</h1>
                  <h2 className="subheading">{post.frontmatter.subtitle}</h2>
                  <span className="meta">Posted by
                    <button className="btn btn-link"> {post.frontmatter.poster} </button>
                    on {post.frontmatter.date}</span>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* Post Content */}
        <article>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-10 mx-auto">
                <div dangerouslySetInnerHTML={{ __html: post.html }} />
             </div>
           </div>
         </div>
       </article>
       <div style={{height:'5rem'}}></div>
       {/* Comments Section (Disqus for now) */}
       <div className="container">
         <div className="row">
           <div className="col-lg-8 col-md-10 mx-auto">
             <h1>Leave a Comment</h1>
             <h2>Thoughts? Critiques? Just saved a bunch of money by switching to GEICO? Leave a Comment!</h2>
             <div id="disqus_thread"></div>
           </div>
         </div>
       </div>
      </div>
    )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        subtitle
        poster
        imageClassName
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`
